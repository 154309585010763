import {
    CREATE_FLASH_MESSAGE,
    SUBMIT_CONTACT_FORM_START,
    SUBMIT_CONTACT_FORM_SUCCESS,
    SUBMIT_CONTACT_FORM_FAILURE,
    NEWSLETTER_SIGNUP_START,
    NEWSLETTER_SIGNUP_SUCCESS,
    NEWSLETTER_SIGNUP_FAILURE
} from 'redux/actionTypes'

import {
    submitContactForm as submitContactFormApi,
    newsletterSignup as newsletterSignupApi,
} from 'redux/api'

export const createFlashMessage = (message) => async dispatch => {
    dispatch({
        type: CREATE_FLASH_MESSAGE,
        payload: message
    })
    return;
}

export const submitContactForm = (contactData) => async dispatch => {
    dispatch({ type: SUBMIT_CONTACT_FORM_START });

    try {
        let response = await submitContactFormApi(contactData);

        dispatch({
            type: SUBMIT_CONTACT_FORM_SUCCESS,
            payload: response
        })

        return response;
    } catch (err) {
        dispatch({
            type: SUBMIT_CONTACT_FORM_FAILURE,
            payload: err,
            error: true
        })

        return new Promise((resolve, reject) => {
            reject(err);
        });
    }
}

export const newsletterSignup = (signupEmail) => async dispatch => {
    dispatch({ type: NEWSLETTER_SIGNUP_START });

    try {
        let response = await newsletterSignupApi(signupEmail);

        dispatch({
            type: NEWSLETTER_SIGNUP_SUCCESS,
            payload: response
        })

        return response;
    } catch (err) {
        dispatch({
            type: NEWSLETTER_SIGNUP_FAILURE,
            payload: err,
            error: true
        })

        return new Promise((resolve, reject) => {
            reject(err);
        });
    }
}