import * as R from 'ramda';

import {
    CREATE_ADMIN_SUCCESS,
    ADMIN_LOGIN_SUCCESS,
    ADMIN_LOGOUT
} from 'redux/actionTypes'; 

const initialState = {
    message: '',
    isAuthenticated: false,
    user: {}
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CREATE_ADMIN_SUCCESS:
            return R.merge(state, {
                message: 'Successfully Added New Admin',
            })
           
        case ADMIN_LOGIN_SUCCESS:
            return R.merge(state, {
                isAuthenticated: true,
                user: payload
            })
        
        case ADMIN_LOGOUT:
            return R.merge(state, {
                isAuthenticated: false, 
                user: payload
            })
            
        default:
            return state;
    }
}