import * as R from 'ramda'

import {
    FETCH_BLOGS_SUCCESS,
    DELETE_BLOG_SUCCESS,
    SEARCH_BLOGS
} from 'redux/actionTypes';

const initialState = {
    draftSlugs: [],
    publishedSlugs: [], 
    search: ''
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_BLOGS_SUCCESS:
            var draftBlogs = [];
            var publishedBlogs = [];
            const checkDraft = blog => {
                if (blog.isDraft === true) {
                    draftBlogs = R.append(blog.blogUrlSlug, draftBlogs);
                } else {
                    publishedBlogs = R.append(blog.blogUrlSlug, publishedBlogs);
                }
            }
            R.forEach(checkDraft, payload.data)

            return R.merge(state, {
                draftSlugs: draftBlogs,
                publishedSlugs: publishedBlogs
            })

        case DELETE_BLOG_SUCCESS:
            console.log('deleting blog reducer', payload)
            if (payload.isDraft === true) {
                return R.merge(state, {
                    draftSlugs: R.without(payload.blogUrlSlug, state.draftSlugs)
                }) 
            }
            else {
                return R.merge(state, {
                    publishedSlugs: R.without(payload.blogUrlSlug, state.publishedSlugs)
                })
            }

        case SEARCH_BLOGS:
            return R.merge(state, {
                search: payload
            })

        default:
            return state;
    }
}