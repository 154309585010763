import React from 'react'
import styled from 'styled-components'

import selfie from 'images/Selfie.png'

const AboutMe = () => (
    <AboutMeWrapper>
        <div className='section-title center-align'>
            <h2>About Me</h2>
            <div className='border'></div>
        </div>
        <div className='row '>
            <div className='col s12 l4 offset-l1 about-img center-align'>
                <img
                    src={selfie}
                    alt='Kyle Barnes'
                />
            </div>
            <div className='col s12 l7'>
                <div className='valign-wrapper'>
                <p className='about-text flow-text'>
                    Hello, and welcome!  My name is Kyle Barnes, and I am a web developer based in the Carson Valley, NV. Since 2018, I have been building top-tier websites from pure computer code.  My clients benefit from the full customization of their site, both in appearance and functionality.  Allow me to take the hassle out of managing your website, so you can focus on what you do best!
                </p>
                </div>
                
            </div>
        </div>
    </AboutMeWrapper>
);

export default AboutMe;

const AboutMeWrapper = styled.section`

    max-width: 100vw;
    background: #111628;

    .section-title {
        padding: 1rem;
        color: white;
        font-family: 'Satisfy', cursive;
    }
    
    .about-img {

        img {
            width: 60%;
            height: auto;
            border-radius: 50%;
            border: 10px solid rgb(121, 173, 172);
            margin-bottom: 20px;
        }
    }

    .row {
        margin: 0 !important;
        padding-bottom: 50px;
    }

    .about-text {
        padding: 25px 25px;
        margin: auto auto;
        width: 80%;
        color: white;
        border: 4px solid rgb(121, 173, 172);
        border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    }

    .border {
        width: 20vw;
        height: 2px;
        background: rgb(121, 173, 172);
        margin: 30px auto;
    }

    @media (min-width: 992px) {
        .about-img {
            img {
                width: 80%;
            }
        }
    }
`;