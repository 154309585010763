import React, { Component } from 'react'
import styled from 'styled-components'
import M from 'materialize-css'

class NavWithDropdowns extends Component {

    componentDidMount() {
        const options = {
            coverTrigger: false,
            hover: true
        }

        M.Dropdown.init(this.Dropdown, options);
        M.Sidenav.init(this.Sidenav);

        this.myNav = React.createRef();
    }

    render() {
        return (
            <MyHeader>

                <ul
                    id="dropdown1"
                    className="dropdown-content"
                >
                    <li><a href="/services/web-design">Website Design</a></li>
                    <li><a href="/services/logo-design">Logo Design</a></li>
                    {/* <li classNameName="divider"></li> */}
                    <li><a href="/services/seo">SEO</a></li>
                    <li><a href="/services/ecommerce">eCommerce</a></li>
                </ul>
                <div className='navbar-fixed' ref={this.myNav}>
                    <nav className='z-depth-0'>
                        <div className="nav-wrapper container">
                            <a href="#!" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                            <ul className="right hide-on-med-and-down">
                                <li><a href="/">Home</a></li>
                                <li><a className="dropdown-trigger" href="/services/web-design" data-target="dropdown1"
                                    ref={Dropdown => {
                                        this.Dropdown = Dropdown;
                                    }}>
                                    Services
                                <i className="material-icons right">arrow_drop_down</i></a>
                                </li>
                                <li><a href="/portfolio">Portfolio</a></li>
                                <li><a href="/blog">Blog</a></li>
                                <li><a href="/contact">Contact</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>

                <ul className="sidenav" id="mobile-demo"
                    ref={Sidenav => {
                        this.Sidenav = Sidenav;
                    }}
                >
                    <li><a href="/">Home</a></li>
                    <li><a href="/services/web-design">Website Design</a></li>
                    <li><a href="/services/logo-design">Logo Design</a></li>
                    <li><a href="/services/seo">SEO</a></li>
                    <li><a href="/services/ecommerce">eCommerce</a></li>
                    <li><a href="/portfolio">Portfolio</a></li>
                    <li><a href="/blog">Blog</a></li>
                    <li><a href="/contact">Contact</a></li>
                </ul> 
            </MyHeader>
        );
    }
}

export default NavWithDropdowns;

const MyHeader = styled.header`
    
    position: absolute;

    nav {
        background: rgba(0,0,0,0.2);
    }

    .dropdown-trigger {
        &:focus {
            outline: none;
        }
    }
`;