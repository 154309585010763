import React, { Component } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import ParallaxHeader from 'pages/MyHomePage/ParallaxHeader'
import Footer from 'components/Footers/Footer'
import Copyright from 'components/Footers/Copyright'
import AboutMe from 'components/Kyle/AboutMe'
import Services from 'components/Kyle/Services'
import Testimonials from 'components/Kyle/Testimonials'
import NewsletterSlider from 'components/Newsletter/NewsletterSlider'

class MyHomePage extends Component {


    componentDidMount() {
    }

    render() {
        return (
            <Wrapper>
                <Helmet>
                    <title>Kyle Barnes Web Design</title>
                    <meta name='description' content='Northern Nevada&#39;s leading expert in Web Design, building completely custom websites.  Have an expert build you a website tailored exactly to your needs.' />
                    <link rel='canonical' href='https://kylebarnesweb.com' />
                    <meta name='robots' content='index, follow' />
                </Helmet>

                <ParallaxHeader />
                <AboutMe />
                <Services />
                <Testimonials />
                <NewsletterSlider />
                <Footer />
                <Copyright />
            </Wrapper>
        );
    }
}

export default MyHomePage;

const Wrapper = styled.div`
    
   
`;