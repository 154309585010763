import React from 'react'
import styled, { keyframes } from 'styled-components'

import bg from 'images/services-bg.jpg'


const Services = props => (
    <MySection>
        <div className='inner-width'>
            <h2 className='section-title'>Services</h2>
            <div className='border'></div>

            <div className='services-container container'>

                <a href='/services/web-design' className='service-box'>
                    <div className='service-icon'>
                        <i className="fas fa-palette"></i>
                    </div>
                    <div className='service-title'>Website Design</div>
                    <div className='service-desc'>
                        Beautiful and efficient websites handmade for each client. Built with speed, tailored needs, and mobile responsiveness in mind.
                    </div>
                </a>

                <a href='/services/logo-design' className='service-box'>
                    <div className='service-icon'>
                        <i className="fas fa-pencil-ruler"></i>
                    </div>
                    <div className='service-title'>Logo Design</div>
                    <div className='service-desc'>
                        Logos crafted by our in-house graphic designer.
                    </div>
                </a>

                <a href='/services/seo' className='service-box'>
                    <div className='service-icon'>
                        <i className="fas fa-bullhorn"></i>
                    </div>
                    <div className='service-title'>SEO</div>
                    <div className='service-desc'>
                        Our websites are built to last and built to perform. Make sure you are ranking high up on Google using our secret tips.
                    </div>
                </a>

                <a href='/services/ecommerce' className='service-box'>
                    <div className='service-icon'>
                        <i className="fas fa-cash-register"></i>
                    </div>
                    <div className='service-title'>eCommerce</div>
                    <div className='service-desc'>
                        Want to sell?  You have come to the right spot.  We provide an admin backend, that allows you to stay up-to-date on orders and view multiple customer reports.
                    </div>
                </a>

            </div>
        </div>

        <div className='portfolio-button'>
            <a href='/portfolio' className='btn button-pulse'>See Portfolio</a>
        </div>
        
    </MySection>
);

export default Services;

const pulse = keyframes`
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(121, 173, 172, 0.7);
  }

  60% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(121, 173, 172, 0);
  }

  100% {
    transform: scale(0.9);
  }
`;

const MySection = styled.section`
    padding: 25px 0;
    background: url(${bg});
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 50vw rgba(0,0,0,0.7);
    background-attachment: fixed;
    max-width: 100vw;
    font-size: 1.1rem;

    .inner-width {
        width: 100%;
        padding: 0 20px;
        margin: auto;
    }

    .section-title {
        text-align: center;
        color: white;
        font-family: 'Satisfy', cursive;
    }

    .border {
        width: 20vw;
        height: 2px;
        background: rgb(121, 173, 172);
        margin: 30px auto;
    }

    .services-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 50px;
        max-width: 100vw;
    }

    .service-box {
        width: 50%;
        padding: 20px;
        text-align: center;
        color: white;
        cursor: pointer;
        margin-bottom: 25px;

        &:hover {
            .service-icon {
                background: rgb(121, 173, 172); 
                color: white;
            }
        }
    }

    .service-icon {
        display: inline-block;
        width: 70px;
        height: 70px;
        border: 3px solid rgb(121, 173, 172);
        color: rgb(121, 173, 172);
        transform: rotate(45deg);
        margin-bottom: 25px;
        margin-top: 1rem;
        transition: all .3s ease-in-out;
        

        i {
            line-height: 70px;
            transform: rotate(-45deg);
            font-size: 24px;
        }
    }

    .service-title {
        font-size: 1.3rem;
        margin-bottom: 10px;
    }

    .portfolio-button {
        display: flex;
        justify-content: center;
        
    }

    .button-pulse {
        animation: ${pulse} 2s infinite;
    }

    @media (max-width: 767px) {
        .service-box {
            width: 80%;
        }
    }

    
`;

