import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "utils/react-auth0-spa";

const AdminRoute = ({ render: Component, path, ...rest }) => {
    const { loading, isAuthenticated, loginWithRedirect, logout, isAdmin } = useAuth0();

    useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }

        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: window.location.pathname }
            });
        };
        fn();
    }, [loading, isAuthenticated, loginWithRedirect, path, isAdmin]);

    let render = props => {
        if (isAuthenticated === true) {
            if (isAdmin === true) {
                return <Component {...props} />
            } else {
                return (
                    <div>
                        <p>You are not signed into an admin account.</p>
                        <button onClick={() => logout()}>Logout</button>
                    </div>
                )
            }
        } else {
            return null;
        }
    }

    return <Route path={path} render={render} {...rest} />;
};

export default AdminRoute;