import * as R from 'ramda'

import {
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE
} from 'redux/actionTypes'

const initialState = {
    isAuthenticated: false
}

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case USER_LOGIN_SUCCESS:
            console.log('login success paylaod', payload);
            return R.merge(state, {
                isAuthenticated: true
            })

        case USER_LOGIN_FAILURE:
            return R.merge(state, {
                isAuthenticated: false
            })
        
        default:
            return state;
    }
}