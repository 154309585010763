import React, { useState, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import Validator from 'validator'
import { connect } from 'react-redux'

import { newsletterSignup, createFlashMessage } from 'redux/actions/utilityActions'

import suitMan from 'images/man-suit.jpg'

const NewsletterSlider = props => {

    const [email, setEmail] = useState('');
    const newsletterForm = useRef(null);

    const handleChange = (e) => {
        const { value } = e.target;
        setEmail(value);
    }

    const checkForm = () => {
        if (email === null) {
            return false;
        }
        else {
            if (!Validator.isEmail(email)) {
                return false
            } else {
                return true;
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log('Submitting email', email);

        if (checkForm()) {
            props.newsletterSignup({ email: email }).then(
                () => {
                    console.log('Newsletter Signup Success');
                    newsletterForm.current.reset();
                    props.createFlashMessage({
                        type: 'success',
                        message: 'Successfully Signed Up'
                    });
                },

                (response) => {
                    newsletterForm.current.reset();
                    props.createFlashMessage({
                        type: 'error',
                        message: 'Error Signing Up'
                    });
                }
            )
        }
        else {
            this.props.createFlashMessage({
                type: 'error',
                message: 'Error in Form'
            });
        }
    }

    return (
        <MyForm
            onSubmit={handleSubmit}
            ref={newsletterForm}
            onChange={handleChange}
        >
            <div className='form-inner'>
                <div className='section-title center-align'>
                    <h2>Pro Tips</h2>
                    <div className='border'></div>
                    <p className='newsletter-text'>Each month I give out FREE tips on maximizing your online potential.<br /> Subscribe below to stay up to date.</p>
                </div>

                <div className='email-box'>
                    <i className="fas fa-envelope"></i>
                    <input
                        className='tbox'
                        type='email'
                        id='newsletter-slider-email'
                        placeholder='Enter your email'
                    />
                    <button type='submit' className='my-btn'>Subscribe</button>
                </div>
            </div>
        </MyForm>
    )
};

const mapDispatchToProps = {
    newsletterSignup,
    createFlashMessage
}

export default connect(null, mapDispatchToProps)(NewsletterSlider);

const pulse = keyframes`
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(121, 173, 172, 0.7);
  }

  60% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(121, 173, 172, 0);
  }

  100% {
    transform: scale(0.9);
  }
`; 

const MyForm = styled.form`

    padding: 25px 0; 
    background: url(${suitMan});
    background-size: cover;
    box-shadow: inset 0 0 0 50vw rgba(0,0,0,0.6);
    background-attachment: fixed;
    background-position: center;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 100vh;

    .form-inner {
        width: 60%;
        padding-bottom: 20px;
    }

    .section-title {
        padding: 1rem;
        color: white;
        font-family: 'Satisfy', cursive;

        .border {
            width: 20vw;
            height: 2px;
            background: rgb(121, 173, 172);
            margin: 30px auto;
        }
    }

    .newsletter-text {
        color: white;
        font-family: 'Roboto', sans-serif;
    }

    .email-box {
        height: 5rem;
        display: flex;
        justify-content: center;

        i {
            width: 5rem;
            line-height: 5rem;
            background: rgb(121, 173, 172);
            color: white;
            text-align: center;
            font-size: 1.6rem;
        }

        &:hover {
            .tbox {
                width: 260px !important;
                margin: 0 15px;
            }

            .my-btn {
                animation: ${pulse} 2s infinite;
            }
        }
    }

    .tbox {
        width: 0 !important;
        transition: .6s !important;
        color: white;

        &:focus {
            width: 260px !important;
            margin: 0 15px;
        }

        &::placeholder {
            color: white;
        }
    }

    .my-btn {
        background: rgb(121, 173, 172);
        color: white;
        padding: 0 10px;
        text-transform: uppercase;
        cursor: pointer;
        outline: none;
        border: none;
    }

    @media (max-width: 575px) {
        .form-inner {
            width: 85%;
        }

        .tbox {
            width: 125px !important;
            padding: 25px !important;
        }

        .email-box {
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        .my-btn {
            padding: 20px;
        }
    }

`;