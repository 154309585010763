import * as R from 'ramda';

import { FETCH_BLOG_BY_SLUG_SUCCESS } from 'redux/actionTypes';

const initialState = {
    blog: {}
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_BLOG_BY_SLUG_SUCCESS:
            return R.merge(state, {
                blog: payload
            });
            
        default:
            return state;
    }
}