import React from 'react';

import DashboardNavbar from 'Admin/NewDashboard/DashboardNavbar'

const WithDashNav = ({children}) => (
    <React.Fragment>
        <DashboardNavbar />
        {children}
    </React.Fragment>
)

export default WithDashNav;