import React from 'react'
import styled from 'styled-components'

// import Selfie from 'images/Selfie.png'
import dennisImg from 'images/dennis-headshot.png'
import jessikaImg from 'images/jessika-headshot'

const Testimonials = props => (
    <MySection>
        <h2 className='section-title'>Testimonials</h2>
        <div className='border'></div>

        <div className='testimonials-container'>
            <a href='http://jessikapaigeskincare.com' target='_blank' rel="noopener noreferrer" className='testimonial'>
                <div className='testimonial-inner'>
                    <div className='layer'></div>
                    <div className='content'>
                        <div className='image-container'>
                            <img
                                src={jessikaImg}
                                alt='Client - Jessika Wass'
                                className='testimonial-image'
                            />
                        </div>
                        <div className='details'>
                            <h3>Jessika Wass</h3>
                        </div>
                        <p>Kyle is a very knowledgeable web developer. Not only does he provide quality work, but he is great at listening to your needs and making your vision come to life. Throughout the process of making my website, Kyle communicated regularly with me and was quick to answer any questions. He is very professional and has a lot of great advice and tools for helping your business grow. </p>
                    </div>
                </div>
            </a>

            <a href='https://otterbeaporpoise.com' target='_blank' rel="noopener noreferrer" className='testimonial'>
                <div className='testimonial-inner'>
                    <div className='layer'></div>
                    <div className='content'>
                        <div className='image-container'>
                            <img
                                src={dennisImg}
                                alt='Client - Dennis Thompkins'
                                className='testimonial-image'
                            />
                        </div>
                        <div className='details'>
                            <h3>Dennis Thompkins</h3>
                        </div>
                        <p>
                            Kyle Barnes is brilliant, and he works with the most current information on web design in a fast-moving industry. He is a good listener and a dedicated worker.<br /><br />
                            What sets him apart from practically every designer is his refusal to use plug-in apps. He builds sites from scratch that perform with superior function and his knowledge of how to create a website to fit the intended purpose is critical.<br /><br />
                            I have worked with designers on four previous web design projects and have been pleased with all of them, however, the one that stands out as the best came from this start-up with a young staff.<br /><br />
                            Within months they had my website in first place on Google organic searches and producing valuable contacts. They were fresh.<br /><br />
                            I recommend Kyle for any web design project without reservation.
                        </p>
                    </div>
                </div>
            </a>

            {/* <div className='testimonial'>
                <div className='testimonial-inner'>
                    <div className='layer'></div>
                    <div className='content'>
                        <div className='image-container'>
                            <img
                                src={Selfie}
                                alt=''
                                className='testimonial-image'
                            />
                        </div>
                        <div className='details'>
                            <h3>Client Name</h3>
                        </div>
                        <p>Kyle was so wonderful. I would recommend him anytime.</p>
                    </div>
                </div>
            </div> */}


        </div>

        

    </MySection>
);

export default Testimonials;

const MySection = styled.section`

    background: #111628;
    padding: 25px 0;

    .section-title {
        text-align: center;
        color: white;
        font-family: 'Satisfy', cursive;
    }

    .border {
        width: 20vw;
        height: 2px;
        background: rgb(121, 173, 172);
        margin: 30px auto;
    }

    .testimonial {
        width: 33.33%;
        display: inline-block;
        margin: 25px 20px;
    }

    .testimonials-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }

    .testimonial-inner {
        position:relative;
        overflow:hidden;
        width: 100%;
        margin:0 auto;
        background:#2c2c2c;
        padding:20px;
        box-sizing:border-box;
        text-align:center;
        box-shadow:0 10px 40px rgba(0,0,0,.5);
        transition: .5s;

        &:hover {
            .layer {
                left: 0;
            }

            transform: translateY(-20px);
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.7);

            .details h3 {
                color: white;
            }

            .content p {
                color: white;
            }
        }
    }

    .layer {
        z-index:1;
        position:absolute;
        top: 0;
        left: 99.5%;
        height:100%;
        width:100%;
        background: #24C6DC; 
        background: -webkit-linear-gradient(to right, #514A9D, #24C6DC);  
        background: linear-gradient(to right, #514A9D, #24C6DC); 
        transition: 0.5s;
    }

    .content {
        z-index: 2; 
        position: relative;

        p {
            font-size:1.1rem;
            line-height:24px;
            color: rgba(255, 255, 255, 0.5);
            transition: .2s;
        }

        .image-container {
            width:100px;
            height:100px;
            margin:0 auto;
            border-radius:50%;
            overflow:hidden;
            border: 3px solid white;
            box-shadow: 0 10px 40px rgba(0,0,0,0.5);
            transition: .5s;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .details {

        h3 {
            font-size:30px;
            color: rgba(255, 255, 255, 0.5);
            transition:0.2s;
        }
    }

    @media (max-width: 767px) {
        .testimonials-container {
            flex-direction: column;
        }

        .testimonial {
            width: 75%;
            margin: 25px auto;

            .details h3 {
                color: white;
            }

            .content p {
                color: white;
            }
        }

        .testimonial-inner {
            .layer {
                left: 0;
            }
        }
    }
`;