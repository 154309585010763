import axios from 'axios'
import qs from 'qs'
import jwt from 'jsonwebtoken'

import setAuthorizationToken from 'utils/setAuthorizationToken'

// import events from 'redux/api/mockData/events.js';

export const createAdmin = async (adminData) => {

    try {
        let response = await axios({
            method: "POST",
            url: "/api/create-admin",
            data: qs.stringify(adminData)
        })

        return response;
    }
    catch (error) {
        return new Promise((resolve, reject) => {
            reject(error.response.data)
        })


    }

};

export const adminLogin = async (loginData) => {
    try {
        let admin = await axios({
            method: "POST",
            url: "/api/admin-login",
            data: qs.stringify(loginData)
        })
            .then(res => {
                const token = res.data.token;
                localStorage.setItem('jwtToken', token);
                setAuthorizationToken(token);
                return (jwt.decode(res.data.token));
            })
        return admin;
    }
    catch (error) {
        return new Promise((resolve, reject) => {
            reject(error.response.data)
        })


    }
}

export const createEvent = async (eventData) => {
    try {
        let response = await axios({
            method: "POST",
            url: "/api/create-event",
            data: qs.stringify(eventData)
        })

        return response;
    }
    catch (error) {
        return new Promise((resolve, reject) => {
            reject(error.response.data)
        })


    }

};



export const fetchEvents = async () => {
    try {
        let events = await axios({
            method: "Get",
            url: "/api/fetch-events"
        })

        return events;
    }
    catch (error) {
        return new Promise((resolve, reject) => {
            reject(error.response.data)
        })
    }

}

export const deleteEvent = async (eventId) => {

    let body = {
        id: eventId
    }

    try {
        let response = await axios({
            method: "POST",
            url: "/api/delete-event",
            data: qs.stringify(body)
        })

        return response;
    }
    catch (error) {
        return new Promise((resolve, reject) => {
            reject(error.response.data)
        })


    }

};

export const createBlog = async (blogData) => {
    try {
        // const formData = new FormData();
        // formData.append('blogTitle', blogData.blogTitle);
        // formData.append('blogUrlSlug', blogData.blogUrlSlug);
        // formData.append('blogShortDescription', blogData.blogShortDescription);
        // formData.append('blogBody', blogData.blogBody);
        // formData.append('blogAuthor', blogData.blogAuthor);
        // formData.append('blogPublishDate', blogData.blogPublishDate);
        // formData.append('blogCategories', blogData.blogCategories);
        // formData.append('oldUrlSlug', blogData.blogUrlSlug);
        // console.log('Image Name', blogData.blogFeaturedImage.name);

    

        // console.log(formData);

        let response = await axios({
            method: "POST",
            url: "/api/create-blog",
            data: qs.stringify(blogData)
        })

        return response;
    }
    catch (error) {
        return new Promise((resolve, reject) => {
            reject(error.response.data)
        })
    }

};

export const fetchBlogs = async () => {
    try {
        let blogs = await axios({
            method: "GET",
            url: "/api/fetch-blogs"
        })

        return blogs;
    }
    catch (error) {
        return new Promise((resolve, reject) => {
            reject(error)
        })
    }

};

export const deleteBlog = async (blogData) => {

    let body = {
        slug: blogData.blogUrlSlug
    }

    try {
        let response = await axios({
            method: "POST",
            url: "/api/delete-blog",
            data: qs.stringify(body)
        })
        console.log('delete blog api response', response);
        return response;
    }
    catch (error) {
        console.log(error);
        return new Promise((resolve, reject) => {
            reject(error.response.data)
        })


    }

};

export const forgotPassword = async (accountEmail) => {
    console.log('API FOR FORGOT PASS');
    try {
        let response = await axios({
            method: "POST",
            url: "/api/forgot-password",
            data: qs.stringify(accountEmail)
        })

        return response;

    } catch (error) {
        return new Promise((resolve, reject) => {
            reject(error.response.data)
        })
    }
}

export const submitContactForm = async (contactData) => {
    try {
        let response = await axios({
            method: "POST",
            url: "/api/contact-form",
            data: qs.stringify(contactData)
        })
        console.log('api response', response);

        return response;

    } catch (error) {
        console.log('api error', error);
        return new Promise((resolve, reject) => {
            reject(error.response)
        })
    }
}

export const fetchBlogCategories = async () => {
    try {
        let blogCategories = await axios({
            method: "GET",
            url: "/api/fetch-blog-categories"
        })

        return blogCategories.data;
    }
    catch (error) {
        return new Promise((resolve, reject) => {
            reject(error)
        })
    }

};

export const addBlogCategory = async (categoryData) => {
    try {
        let response = await axios({
            method: "POST",
            url: "/api/create-blog-category",
            data: qs.stringify(categoryData)
        })

        return response;
    }
    catch (error) {
        return new Promise((resolve, reject) => {
            reject(error.response.data)
        })
    }

};

export const fetchBlogBySlug = async (slug) => {
    const blogSlug = { slug };
    console.log(qs.stringify(blogSlug));
    try {
        let returnedBlog = await axios({
            method: "POST",
            url: "/api/fetch-blog",
            data: qs.stringify(blogSlug)
        })

        console.log('returnedBlog', returnedBlog.data)
        return returnedBlog.data;
    } catch (err) {
        return err;
    }
};

export const deleteBlogCategory = async (blogData) => {

    console.log(blogData)

    let body = {
        categorySlug: blogData.activeCategoryName
    }

    try {
        let response = await axios({
            method: "POST",
            url: "/api/delete-blog-category",
            data: qs.stringify(body)
        })
        console.log('delete blog category api response', response);
        return response;
    }
    catch (error) {
        console.log('api error', error);
        return new Promise((resolve, reject) => {
            reject(error.response.data)
        })


    }

};

export const updateBlog = async (blogData) => {

    try {
        let response = await axios({
            method: "PUT",
            url: "/api/update-blog",
            data: qs.stringify(blogData)
        })
        return response;
    }
    catch (error) {
        console.log(error);
        return new Promise((resolve, reject) => {
            reject(error.response.data)
        })


    }

};

export const newsletterSignup = async (signupEmail) => {
    try {
        let response = await axios({
            method: "POST",
            url: "/api/newsletter-signup",
            data: qs.stringify(signupEmail)
        })
        console.log('api response', response);

        return response;

    } catch (error) {
        console.log('api error', error);
        return new Promise((resolve, reject) => {
            reject(error.response)
        })
    }
};