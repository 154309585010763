import * as R from 'ramda';

import {
    FETCH_BLOG_CATEGORIES_SUCCESS,
    DELETE_BLOG_CATEGORY_SUCCESS
} from "redux/actionTypes";

const initialState = {}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_BLOG_CATEGORIES_SUCCESS:
            const newValues = R.indexBy(R.prop('blogCategorySlug'), payload)
            return R.merge(state, newValues)
        
        case DELETE_BLOG_CATEGORY_SUCCESS:
            const deleteCategory = R.dissoc(payload.activeCategoryName, state);
            state = deleteCategory;
            return state; 
            
        default:
            return state;
    }
}