import React from 'react';

import NavWithDropdowns from 'components/Navbars/NavWithDropdowns'

const WithNavbar = ({children}) => (
    <React.Fragment>
        <NavWithDropdowns />
        {children}
    </React.Fragment>
)

export default WithNavbar;