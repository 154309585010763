import React, { Component } from 'react';
import styled from 'styled-components'

import logo from 'images/kb-logo.png'

class Footer extends Component {
    render() {
        return (
            <FooterContainer >
                <div className="row container">
                    <div className="col s10 offset-s1 l4 logo">
                        <a href='/'>
                            <img
                                src={logo}
                                alt='Kyle Barnes Web Design'
                                className='logo-footer'

                            />
                        </a>
                    </div>

                    <div className="col s10 offset-s1 l4">
                        <h4>Links</h4>
                        <div className='quick-links'>
                            <a href='/' className='mt-1'>Home</a>
                            <a href="/services/web-design">Website Design</a>
                            <a href="/services/logo-design">Logo Design</a>
                            <a href="/services/seo">SEO</a>
                            <a href="/services/ecommerce">eCommerce</a>
                            <a href="/portfolio">Portfolio</a>
                            <a href="/contact">Contact</a>
                        </div>
                    </div>

                    <div className="col s10 offset-s1 l4">
                        <h4>Contact</h4>
                        <div className='contact-info'>
                            <i className="fas fa-envelope mb-2"></i>
                            <a href='mailto:kyle@kylebarnesweb.com'>kyle@kylebarnesweb.com</a>
                        </div>

                        <div className='contact-info mt-3'>
                            <i className="fas fa-phone fa-flip-horizontal mt-1 mb-2"></i>
                            <a href='tel:7757909741'>(775) 790-9741</a>
                        </div>
                    </div>
                </div>

            </FooterContainer>
        )
    }
}

export default Footer;

const FooterContainer = styled.div`
    display: flex;
    background: rgb(51, 82, 96);
    padding: 15px;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;

    .row {
        margin: 0;
        width: 100%;
    }

    .logo {
        margin-top: 15px;
    }

    ul {
        list-style: none;
        justify-content: center;
        padding: 0;
    }

    i {
        margin: 0;
    }
        
    .social-btn {
        display: flex;
        align-items: center;
        border: white solid 1px;
        color: white;
        width: 150px;
        justify-content: space-evenly;
        margin: auto;
        height: 35px;

        &:hover {
            filter: brightness(75%);
            text-decoration: none;
        }

        a {
            color: inherit;
            display: inherit;
        }
        
        p {
            margin: 0;
            vertical-align: middle;
        }

    }

    .logo-footer {
            width: 200px;
            height: auto;
        }

    .quick-links {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        
        a {
            color: white;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .follow-header {
        margin-bottom: 1rem;
    }

    .contact-info {
        display: flex;
        justify-content: center;
        flex-direction: column;

        a {
            vertical-align: middle;
            color: white;
        }
    }

    .facebook {
        background: #3b5998;
    }

    .instagram {
        background: #d6249f;
        background: radial-gradient(circle at 10% 107%, #fdf497 0%, #fdf497 5%, #fd5949 35%,#d6249f 60%,#285AEB 90%);
    }
    
    @media (min-width: 576px) { 
    }

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) { 
        
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) { 
        .logo-footer {
            width: 200px;
            height: auto;
        }
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) { 
   
    }
`;
