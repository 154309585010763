//https://coolors.co/dbcfb0-bfc8ad-90b494-718f94-545775
export const theme = {
    colors: {
        primary: '#173753',
        secondary: '#0275d8',
        accent1: '#ffab40',
        accent2: '#ff6d00',
        background: 'rgba(0,0,0,0.5)',
        offWhite: 'white'
    }
};