import * as R from 'ramda';

import {
    FETCH_EVENTS_SUCCESS,
    CREATE_EVENT_SUCCESS,
    DELETE_EVENTS_SUCCESS
} from 'redux/actionTypes'
const initialState = {}

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case FETCH_EVENTS_SUCCESS:
            let data = payload.data;
            const newValues = R.indexBy(R.prop('_id'), data);
            return R.merge(state, newValues);
        
        case CREATE_EVENT_SUCCESS:
            let newData = payload.data;
            const newEvent = R.indexBy(R.prop('_id'), newData);
            return R.merge(state, newEvent);
        
        case DELETE_EVENTS_SUCCESS:
            const deleteEvent = R.dissoc(payload, state);
            state = deleteEvent;
            return state;
        default:
            return state;
    }
}