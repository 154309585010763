import * as R from 'ramda';

import {
    FETCH_BLOGS_SUCCESS,
    CREATE_BLOG_SUCCESS,
    DELETE_BLOG_SUCCESS
} from 'redux/actionTypes'
const initialState = {}

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case FETCH_BLOGS_SUCCESS:
            let data = payload.data;
            const newValues = R.indexBy(R.prop('blogUrlSlug'), data);
            return R.merge(state, newValues);
        
        case CREATE_BLOG_SUCCESS:
            let newData = payload.data;
            console.log('newData', newData)
            const newBlog = R.indexBy(R.prop('blogUrlSlug'), newData);
            return R.merge(state, newBlog);
        
        case DELETE_BLOG_SUCCESS:
            const deleteBlog = R.dissoc(payload, state);
            state = deleteBlog;
            return state;
        default:
            return state;
    }
}