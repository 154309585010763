import React from 'react'
import styled from 'styled-components'

import parallax from 'utils/parallax'

import cloud from 'images/parallax/cloud.svg'
import layer1 from 'images/parallax/layer-1.svg'
import layer2 from 'images/parallax/layer-2.svg'
import layer3 from 'images/parallax/layer-3.svg'
import layer4 from 'images/parallax/layer-4.svg'
import logo from 'images/kb-logo.png'


class ParallaxHeader extends React.Component {

    componentDidMount() {
        parallax();
    }

    render() {
        return (
            <MyHeader>
                <header>
                    <img alt='cloud' id="cloud1" className="layer cloud small" data-offset="0.7" src={cloud} />
                    <img alt='cloud' id="cloud2" className="layer cloud" data-offset="0.3" src={cloud} />
                    <img alt='cloud' id="cloud3" className="layer cloud small" data-offset="0.4" src={cloud} />

                    <img alt='mountain' id="layer4" className="layer" data-offset="1" src={layer4} />
                    <img alt='mountain' id="layer3" className="layer" data-offset="0.7" src={layer3} />
                    <img alt='mountain' id="layer2" className="layer" data-offset="0.5" src={layer2} />
                    <img alt='logo' id="logo" className="layer" data-offset="1.25" width="300" src={logo} />
                    <img alt='mountain' id="fg" src={layer1} />
                </header>
                
                
            </MyHeader>
        )
    }
}

export default ParallaxHeader;

const MyHeader = styled.div`

    header {
        position: relative;
        height: 100vh;
        background: linear-gradient(to bottom,  #589daa 0%, #e3e7db 30%);
        background-attachment: fixed;
        overflow-y: hidden;
    }

    #logo{
        display: block;
        margin: auto;
        position: relative;
        top: 150px;
    }

    #fg{
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .layer{
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .cloud{
        width: 300px;
        bottom: auto;
    }

    .small{
        width: 100px;
    }

    #cloud1{
        left: 50%;
        top: 200px;
    }

    #cloud2{
        left: 10%;
        top: 100px;
    }

    #cloud3{
        left: 70%;
        top: 50px;
        width: 150px;
    }

    .spacer {
        position: relative;
        height: 500px;
        background: #111628;
    }

    #canvas{
        display: block;
        margin: auto;
    }
`;