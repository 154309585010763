import {combineReducers} from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from 'redux/reducers/auth'
import admin from 'redux/reducers/admin'
import events from 'redux/reducers/events'
import currentEvents from 'redux/reducers/currentEvents'
import flashMessages from 'redux/reducers/flashMessages'
import blogs from 'redux/reducers/blogs'
import blogsPage from 'redux/reducers/blogsPage'
import blogPage from 'redux/reducers/blogPage'
import blogCategories from 'redux/reducers/blogCategories'


export default history => combineReducers({
  router: connectRouter(history),
  auth,
  admin, 
  events,
  currentEvents,
  flashMessages,
  blogs,
  blogPage,
  blogsPage,
  blogCategories
})