import React, { Component } from 'react'
import styled from 'styled-components'

export default class Copyright extends Component {
    render() {
        return (
            <CopyrightContainer>
                <div className='container'>
                    <div className='row'>
                        <div className='col s12 m6'>
                            <p>
                                Designed By <a href='https://kylebarnesweb.com' target="_blank" rel="noopener noreferrer" ><em>Kyle Barnes Web Design</em></a>
                            </p>
                        </div>

                        <div className='col s12 m6'>
                            <p>
                                Copyright &copy; {(new Date().getFullYear())} Kyle Barnes Web Design. All Rights Reserved.
                            </p>
                            
                        </div>
                    </div>
                </div>
                    
                
                

            </CopyrightContainer>
        );
    }
}

const CopyrightContainer = styled.footer`
    display: flex;  
    justify-content: center;
    align-items: center;
    text-align:center;
    width: 100%;
    padding-top: 15px;
    
    background: black;
    border-top: solid white 1px;
    color: white;

    a {
        color: inherit;
    }

`;