import shortid from 'shortid'
import M from 'materialize-css'

import {
    CREATE_FLASH_MESSAGE
} from 'redux/actionTypes'

const initialState = []

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case CREATE_FLASH_MESSAGE:
            if (payload.type === 'success') {
                M.toast({ html: `${payload.message}`, classes: 'green' });
            } else if (payload.type === 'error') {
                M.toast({ html: `${payload.message}`, classes: 'red' });
            } else {
                M.toast({ html: `${payload.message}`});
            }
            return [
                ...state,
                {
                    id: shortid.generate(),
                    type: payload.type,
                    message: payload.message
                }
            ]
            
        default:
            return state;
    }
}