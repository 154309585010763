export const CREATE_ADMIN_START = 'CREATE_ADMIN_START';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE';

export const ADMIN_LOGIN_START = 'ADMIN_LOGIN_START';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAILURE = 'ADMIN_LOGIN_FAILURE';

export const CREATE_EVENT_START = 'CREATE_EVENT_START';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const FETCH_EVENTS_START = 'FETCH_EVENTS_START';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';

export const DELETE_EVENTS_START = 'DELETE_EVENTS_START';
export const DELETE_EVENTS_SUCCESS = 'DELETE_EVENTS_SUCCESS';
export const DELETE_EVENTS_FAILURE = 'DELETE_EVENTS_FAILURE';

export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';

export const CREATE_FLASH_MESSAGE = 'CREATE_FLASH_MESSAGE';

export const CREATE_BLOG_START = 'CREATE_BLOG_START';
export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS';
export const CREATE_BLOG_FAILURE = 'CREATE_BLOG_FAILURE';

export const FETCH_BLOGS_START = 'FETCH_BLOGS_START';
export const FETCH_BLOGS_SUCCESS = 'FETCH_BLOGS_SUCCESS';
export const FETCH_BLOGS_FAILURE = 'FETCH_BLOGS_FAILURE';

export const DELETE_BLOG_START = 'DELETE_BLOG_START';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_FAILURE = 'DELETE_BLOG_FAILURE';

export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const SUBMIT_CONTACT_FORM_START = 'SUBMIT_CONTACT_FORM_START';
export const SUBMIT_CONTACT_FORM_SUCCESS = 'SUBMIT_CONTACT_FORM_SUCCESS';
export const SUBMIT_CONTACT_FORM_FAILURE = 'SUBMIT_CONTACT_FORM_FAILURE';

export const SEARCH_BLOGS = 'SEARCH_BLOGS';

export const FETCH_BLOG_CATEGORIES_START = 'FETCH_BLOG_CATEGORIES_START';
export const FETCH_BLOG_CATEGORIES_SUCCESS = 'FETCH_BLOG_CATEGORIES_SUCCESS';
export const FETCH_BLOG_CATEGORIES_FAILURE = 'FETCH_BLOG_CATEGORIES_FAILURE';

export const CREATE_BLOG_CATEGORY_START = 'CREATE_BLOG_CATEGORY_START';
export const CREATE_BLOG_CATEGORY_SUCCESS = 'CREATE_BLOG_CATEGORY_SUCCESS';
export const CREATE_BLOG_CATEGORY_FAILURE = 'CREATE_BLOG_CATEGORY_FAILURE';

export const FETCH_BLOG_BY_SLUG_START = 'FETCH_BLOG_BY_SLUG_START';
export const FETCH_BLOG_BY_SLUG_SUCCESS = 'FETCH_BLOG_BY_SLUG_SUCCESS';
export const FETCH_BLOG_BY_SLUG_FAILURE = 'FETCH_BLOG_BY_SLUG_FAILURE';

export const DELETE_BLOG_CATEGORY_START = 'DELETE_BLOG_CATEGORY_START';
export const DELETE_BLOG_CATEGORY_SUCCESS = 'DELETE_BLOG_CATEGORY_SUCCESS';
export const DELETE_BLOG_CATEGORY_FAILURE = 'DELETE_BLOG_CATEGORY_FAILURE';

export const UPDATE_BLOG_START = 'UPDATE_BLOG_START';
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS';
export const UPDATE_BLOG_FAILURE = 'UPDATE_BLOG_FAILURE';

export const NEWSLETTER_SIGNUP_START = 'NEWSLETTER_SIGNUP_START';
export const NEWSLETTER_SIGNUP_SUCCESS = 'NEWSLETTER_SIGNUP_SUCCESS';
export const NEWSLETTER_SIGNUP_FAILURE = 'NEWSLETTER_SIGNUP_FAILURE';