import React, { Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router'
import { ThemeProvider } from 'styled-components'

import { theme } from 'theme.js'

//Auth0
// import PrivateRoute from "utils/PrivateRoute";
import AdminRoute from "utils/AdminRoute"
import WithNavbar from 'utils/WithNavbar'
import WithDashNav from 'utils/WithDashNav'

import MyHomePage from 'pages/MyHomePage'

// const MyHomePage = lazy(() => import('pages/MyHomePage')); 
const WebDesign = lazy(() => import('pages/WebDesign')); 
const LogoDesign = lazy(() => import('pages/LogoDesign'));
const SEO = lazy(() => import('pages/SEO'));
const ECommerce = lazy(() => import('pages/ECommerce'));
const Portfolio = lazy(() => import('pages/Portfolio'));
const ContactPage = lazy(() => import('pages/ContactPage'));
const BlogPage = lazy(() => import('pages/BlogPage'));

const Dashboard = lazy(() => import('Admin/NewDashboard'));
const Events = lazy(() => import('Admin/NewDashboard/Events.js'));
const Blog = lazy(() => import('Admin/NewDashboard/Blog.js'));
const AddBlogCategory = lazy(() => import('Admin/NewDashboard/AddBlogCategory.js'));
const CreateBlog = lazy(() => import('components/Forms/Templates/CreateBlog'));
const BlogInfoPage = lazy(() => import('pages/BlogInfoPage'));
const PageNotFound = lazy(() => import('pages/DennisThompkins/PageNotFound'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));


// import HomePage from 'pages/DennisThompkins/HomePage'
// import AboutPage from 'pages/DennisThompkins/AboutPage'
// import Navbar from 'components/Navbars/Navbar'


export default (
  <ThemeProvider theme={theme}>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path='/' render={() => <WithNavbar><MyHomePage /></WithNavbar>} exact />

        <Route path='/services/web-design' render={() => <WithNavbar><WebDesign /></WithNavbar>} />
        <Route path='/services/logo-design' render={() => <WithNavbar><LogoDesign /></WithNavbar>} />
        <Route path='/services/seo' render={() => <WithNavbar><SEO /></WithNavbar>} />
        <Route path='/services/ecommerce' render={() => <WithNavbar><ECommerce /></WithNavbar>} />
        <Route path='/portfolio' render={() => <WithNavbar><Portfolio /></WithNavbar>} />
        <Route path='/contact' render={() => <WithNavbar><ContactPage /></WithNavbar>} />
        <Route exact path='/blog' render={() => <WithNavbar><BlogPage /></WithNavbar>} />
        <Route path='/blog/:slug' render={() => <WithNavbar><BlogInfoPage /></WithNavbar>} />

        <AdminRoute exact path='/admin' render={() => <WithDashNav><Dashboard /></WithDashNav>} />
        <AdminRoute path='/admin/events' render={() => <WithDashNav><Events /></WithDashNav>} />
        <AdminRoute exact path='/admin/blog' render={() => <WithDashNav><Blog /></WithDashNav>} />
        <AdminRoute exact path='/admin/blog/create-blog' render={() => <WithDashNav><CreateBlog /></WithDashNav>} />
        <AdminRoute exact path='/admin/blog/edit/:slug' render={() => <WithDashNav><CreateBlog editForm /></WithDashNav>} />
        <AdminRoute path='/admin/blog/category/:slug' render={() => <WithDashNav><Blog /></WithDashNav>} />
        <AdminRoute path='/admin/blog/add-category' render={() => <WithDashNav><AddBlogCategory /></WithDashNav>} />

        <Route exact path='/privacy-policy' render={() => <WithNavbar><PrivacyPolicy /></WithNavbar>} />
        <Route component={PageNotFound} />

      </Switch>
    </Suspense>
  </ThemeProvider>
);