import React, { Component } from 'react'
import styled from 'styled-components'
import M from 'materialize-css'

import { Auth0Context } from "utils/react-auth0-spa";

class DashboardNavbar extends Component {

    static contextType = Auth0Context;

    componentDidMount() {
        M.Tabs.init(this.Tabs);
        M.Sidenav.init(this.Sidenav);
        
    }

    render() {
        const { logout } = this.context;

        return (
            <DashNavWrapper>
                <div className='navbar-fixed'>
                <nav className="nav-extended">
                    <div className="nav-wrapper">
                        <a href="/admin" className="brand-logo">Admin Panel</a>
                        <a href='/#' data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                        <ul id="nav-mobile" className="right hide-on-med-and-down">
                            <li><a href="/">View Site <i className="material-icons right">home</i></a></li>
                            <li><a href='#!' onClick={() => logout()} className=''>Log Out <i className="material-icons right">label_important</i></a></li>
                        </ul>
                    </div>
                    <div className="nav-content">
                        <ul className='tabs tabs-transparent tabs-fixed-width'>
                            <li className='tab'><a className='active' href='/admin'>Home</a></li>
                            <li className='tab'><a href='/admin/events'>Events</a></li>
                            <li className='tab'><a href='/admin/blog'>Blog</a></li>
                        </ul>
                    </div>
                </nav>
                </div>

                <ul className="sidenav" id="mobile-demo"
                    ref={Sidenav => {
                        this.Sidenav = Sidenav;
                    }}
                > 
                    <li><a href="/">View Site <i className="material-icons right">home</i></a></li>
                            <li><a href="#!" onClick={() => logout()}>Log Out <i className="material-icons right">label_important</i></a></li>
                </ul>

            </DashNavWrapper>
        )
    }
}

export default DashboardNavbar;

const DashNavWrapper = styled.div`
    position: absolute;

    .brand-logo {
        margin-left: 20px;
    }
    
    .nav-extended {
        background: ${props => props.theme.colors.background} !important;
    }
    
`;