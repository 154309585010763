import * as R from 'ramda';

import {
    FETCH_EVENTS_SUCCESS,
    CREATE_EVENT_SUCCESS,
    DELETE_EVENTS_SUCCESS
} from 'redux/actionTypes'

const initialState = {
    ids: []
}

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case FETCH_EVENTS_SUCCESS:
            return R.merge(state, {
                ids: R.pluck('_id', payload.data)
            })
        case CREATE_EVENT_SUCCESS:
            console.log('currentIds', state.ids);
            const newIds = R.append(payload.data[0]._id, state.ids);
            return R.merge(state, {
                ids: newIds
            })
            
        case DELETE_EVENTS_SUCCESS:
            return R.merge(state, {
                ids: R.without(payload, state.ids)
            });
            
        default:
            return state;
    }
}